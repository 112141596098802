import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import { screen } from "../../styles/theme"
import GlobalStyles from "../../styles/GlobalStyles"
import Head from "../Head"
import "../../styles/fonts.css"

const Layout = ({ children }) => (
  <ThemeProvider theme={{ screen }}>
    <>
      <Head />
      <GlobalStyles />
      {children}
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
